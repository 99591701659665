import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import { Link } from "gatsby"

const AboutPage = () => (
  <Layout>
    <SEO title="Affiliate Disclosure" noindex />
    <Container fluid={true} className="p-0">
      <Row className="m-0">
        <Col className="p-0" />
      </Row>
    </Container>
    <Container fluid={true}>
      <Row>
        <Col className="about-content py-4">
          <AboutContent>
            <h1 className="text-center">Affiliate Disclosure</h1>
            <ShortLine />

            <p>
              While we hope you benefit from the information contained here on
              this site, we are a <u>for-profit</u> operation and want to ensure
              that you understand we use affiliate programs to generate
              revenues.
            </p>
            <p>
              <strong>
                Keep in mind the price you pay for third party products and
                services does not increase because we receive a commission on
                the sale.
              </strong>
            </p>
            <p>
              To this end, Chicken-Yard.net is a participant in the Amazon
              Services LLC Associates Program, an affiliate advertising program
              designed to provide a means for sites to earn advertising fees by
              advertising and linking to Amazon.com.
            </p>
            <p>
              If you have any questions, please contact us{" "}
              <Link to="/contact/">here</Link>.
            </p>
          </AboutContent>
        </Col>
      </Row>
    </Container>
  </Layout>
)

export default AboutPage

const ShortLine = styled.hr`
  width: 75%;
`
const AboutContent = styled.div`
  max-width: 665px;
  margin: auto;
  padding-bottom: ;
`
